import React, { useState } from 'react';
import './PhotoGrid.css'; // Assuming you have your CSS in this file

function PhotoGrid({ handleValidate }) { // Recevoir handleValidate comme prop
    const [images, setImages] = useState(Array(12).fill(null)); // 12 cells
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [modalImage, setModalImage] = useState(null);
    const [modalText, setModalText] = useState(''); // Track text input for description
    const [selectedDate, setSelectedDate] = useState(''); // Track selected date
    const [imageFile, setImageFile] = useState(null); // Track selected image file

    // Function to handle image selection inside the modal
    const handleModalImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file); // Save the image file
            const reader = new FileReader();
            reader.onload = () => {
                setModalImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // Function to handle cell click and open modal
    const handleCellClick = (index) => {
        setSelectedIndex(index);
        setModalImage(images[index]);
        setModalText(''); // Reset text when opening the modal
        setSelectedDate(''); // Reset date when opening the modal
        setImageFile(null); // Reset image file when opening the modal
        setIsModalOpen(true);
    };

    // Function to handle saving the image, text, and date from the modal
    const handleSave = async () => {
        if (!modalText || !selectedDate || !imageFile) {
            alert('Veuillez remplir tous les champs et ajouter une image.');
            return;
        }

        try {
            // Appel de la fonction handleValidate provenant de MainScreen.js
            await handleValidate(modalText, selectedDate, imageFile); // Transmettre les valeurs

            console.log('Publication valid�e avec succ�s');

            const newImages = [...images];
            newImages[selectedIndex] = modalImage;
            setImages(newImages);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Erreur lors de la validation de la publication:', error);
            alert('Erreur lors de la validation de la publication.');
        }
    };

    // Close modal when clicking outside
    const handleCloseModal = (e) => {
        if (e.target.id === 'modalBackground') {
            setIsModalOpen(false);
        }
    };

    return (
        <div className="grid-container">
            {/* Grid Layout centered in the screen */}
            <div className="photo-grid">
                {images.map((image, index) => (
                    <div key={index} className="grid-cell" onClick={() => handleCellClick(index)}>
                        {image ? (
                            <img src={image} alt="Selected" className="grid-image" />
                        ) : (
                            <div className="upload-placeholder">
                                <i className="fas fa-camera camera-icon"></i> {/* Font Awesome camera icon */}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Modal Popup */}
            {isModalOpen && (
                <div id="modalBackground" className="modal-background" onClick={handleCloseModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        {/* Larger Image Upload */}
                        <div className="modal-image-section">
                            {modalImage ? (
                                <img src={modalImage} alt="Selected" className="modal-image" />
                            ) : (
                                <label className="upload-button">
                                    <input type="file" style={{ display: 'none' }} onChange={handleModalImageUpload} />
                                    <i className="fas fa-camera camera-icon"></i> {/* Font Awesome camera icon */}
                                </label>
                            )}
                        </div>

                        {/* Text Input */}
                        <div className="modal-textarea-container">
                            <textarea
                                value={modalText}
                                onChange={(e) => setModalText(e.target.value)}
                                placeholder="Ajoutez une description..."
                                className="modal-textarea"
                            />
                        </div>

                        {/* Date Picker */}
                        <div className="date-picker">
                            <label htmlFor="date-input">Select a date:</label>
                            <input
                                type="date"
                                id="date-input"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                                className="modal-date-input"
                            />
                        </div>

                        {/* Close and Save Buttons */}
                        <div className="modal-buttons">
                            <button className="modal-button close-button" onClick={() => setIsModalOpen(false)}>
                                Fermer
                            </button>
                            <button
                                className="modal-button save-button"
                                onClick={handleSave} // Appel de handleSave qui appelle handleValidate
                                disabled={!modalText || !selectedDate || !imageFile} // Disable Save button if there's no text, date or image
                            >
                                Valider
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PhotoGrid;
