import React, { useEffect, useState } from 'react';
import './MainScreen.css';
import logo from '../img/logoComeal.png'; // Importer l'image
import { db, storage } from '../firebase'; // Importer Firestore et Firebase Storage
import { collection, getDocs, doc, setDoc } from 'firebase/firestore'; // Importer les fonctions Firestore
import { ref, uploadBytes } from 'firebase/storage'; // Importer les fonctions Firebase Storage
import PhotoGrid from './PhotoGrid'; // Adjust the path based on where you store PhotoGrid.js


const MainScreen = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [caption, setCaption] = useState('');
  const [date, setDate] = useState('');
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // Ajout de l'état pour la recherche
  const [isValidating, setIsValidating] = useState(false); // État pour gérer l'animation

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsCollection = collection(db, 'Client');
        const clientsSnapshot = await getDocs(clientsCollection);
        const clientsList = clientsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setClients(clientsList);
      } catch (error) {
        console.error("Erreur lors de la récupération des clients :", error);
      }
    };

    fetchClients();
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClientClick = (clientId) => {
    setSelectedClient(clientId);
  };

  const getMonthYearStringEnglish = (date) => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const d = new Date(date);
    const month = months[d.getMonth()];
    const year = d.getFullYear();
    return `${month}${year}`;
  };

  const getMonthYearStringFrench = (date) => {
    const months = [
      "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
      "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ];
    const d = new Date(date);
    const month = months[d.getMonth()];
    const year = d.getFullYear();
    return `${month}${year}`;
  };

  const generateId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const setId = (docRef, id) => {
    return setDoc(docRef, { id: id }, { merge: true });
  };

  const resetForm = () => {
    setCaption('');
    setDate('');
    setImage(null);
    setImageFile(null);
    setIsValidating(false);
  };

    const handleValidate = async (caption, date, imageFile) => {
        if (!selectedClient) {
            alert("Veuillez sélectionner un client.");
            return;
        }

        if (!caption || !date || !imageFile) {
            alert("Veuillez remplir tous les champs et ajouter une image.");
            return;
        }

        const monthYearStringEnglish = getMonthYearStringEnglish(date);
        const monthYearStringFrench = getMonthYearStringFrench(date);
        const publicationId = generateId();
        const clientPublicationsCollection = `${selectedClient}Publications`;

        try {
            setIsValidating(true);

            // Upload de l'image dans Firebase Storage
            const storagePath = `shops/${selectedClient}/${monthYearStringFrench}/${imageFile.name}`;
            const storageRef = ref(storage, storagePath);
            await uploadBytes(storageRef, imageFile);
            const imageUrl = `gs://comealapplication.appspot.com/${storagePath}`;

            // Mise à jour du document existant dans la collection Client
            const documentRef = doc(db, selectedClient, monthYearStringEnglish);
            await setDoc(documentRef, {
                [publicationId]: publicationId
            }, { merge: true });

            // Ajout d'un nouveau document dans la collection ClientPublications
            const newPublicationRef = doc(db, clientPublicationsCollection, publicationId);
            await setDoc(newPublicationRef, {
                id: publicationId, // Ajout de l'ID généré
                caption: caption,
                date: date,
                status: 'TO_VALIDATE',
                imgUrl: imageUrl // Utilisation de "imgUrl" comme nom du champ
            });

            setTimeout(resetForm, 1000); // Réinitialiser le formulaire après l'animation

        } catch (error) {
            console.error("Erreur lors de l'ajout de la publication :", error);
            alert("Erreur lors de l'ajout de la publication.");
            setIsValidating(false);
        }
    };


  // Fonction de filtrage des clients basée sur le terme de recherche
  const filteredClients = clients.filter(client =>
    client.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

 

    return (
        <div className="main-screen">
            <div className="sidebar">
                <img src={logo} alt="Logo" className="logo" />
                <h2>Mes Clients</h2>
                <input
                    type="text"
                    placeholder="Rechercher un client"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
                <ul>
                    {filteredClients.map(client => (
                        <li key={client.id} onClick={() => handleClientClick(client.id)} className={selectedClient === client.id ? 'selected' : ''}>
                            {client.name}
                        </li>
                    ))}
                </ul>
            </div>

            <div className="content">
                <h1>Calendrier de Publications</h1>
                <h2>Octobre 2024</h2>
                {/* Insert PhotoGrid Here */}
                <div className="photo-grid-section">
                    <PhotoGrid handleValidate={handleValidate} />
                </div>
                {/* Existing Upload Section */}
                {/*<div className="upload-section">
                    {!image ? (
                        <button className="upload-button" onClick={() => document.getElementById('imageInput').click()}>
                            <i className="fas fa-camera"></i>
                        </button>
                    ) : (
                        <img src={image} alt="Uploaded" className={`uploaded-image ${isValidating ? 'fade-out' : ''}`} />
                    )}
                    <input
                        type="file"
                        id="imageInput"
                        style={{ display: 'none' }}
                        onChange={handleImageUpload}
                    />

                    <div className="caption-date-container">
                        <input
                            type="text"
                            placeholder="Caption"
                            value={caption}
                            onChange={(e) => setCaption(e.target.value)}
                            className="caption-input"
                        />
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            className="date-input"
                        />
                    </div>
                </div>
                */}
            </div>
 
            <div className="sidebar">
                <img src={logo} alt="Logo" className="logo" />
                <h2>Client</h2>

               

                <div className="button-group">
                    <button className="action-button">Calendrier de Publications</button>
                    <button className="action-button">Chat en direct</button>
                    <button className="action-button">Envoi de Notifications</button>
                </div>
            </div>
        </div>
    );
};

export default MainScreen;
