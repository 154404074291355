// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import './Login.css'; // Importer le fichier CSS
import logo from '../img/logoComeal.png'; // Importer l'image

const Login = ({ setIsAuthenticated }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {
            await signInWithEmailAndPassword(auth, email, password);
            // Met à jour l'état d'authentification
            setIsAuthenticated(true);
            // Rediriger l'utilisateur vers la page d'accueil après une connexion réussie
            navigate('/');
        } catch (err) {
            setError('Le mot de passe est incorrect ou l\'utilisateur n\'existe pas');
        } finally {
            setLoading(false);
        }
    };

    const handleFillTestCredentials = () => {
        setEmail('admin@test.com');
        setPassword('testtest');
    };

    const handleFillTestCredentialsJean = () => {
        setEmail('Jeancomeal@test.com');
        setPassword('jeanjean');
    };
    const handleFillTestCredentialsEmma = () => {
        setEmail('Emmacomeal@test.com');
        setPassword('emmaemma');
    };
    const handleFillTestCredentialsPaul = () => {
        setEmail('Paulcomeal@test.com');
        setPassword('paulpaul');
    };
    const handleFillTestCredentialsClement = () => {
        setEmail('Clementcomeal@test.com');
        setPassword('clementclement');
    };

    return (
        <div className="login-container">
            <img src={logo} alt="Logo" className="logo" />
            <h1 className="welcome-message">Bienvenue sur votre Interface Comeal</h1>
            <form onSubmit={handleLogin} className="login-form">
                <div className="form-group">
                    <label>Email :</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-input"
                    />
                </div>
                <div className="form-group">
                    <label>Mot de passe :</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-input"
                    />
                </div>
                {error && <p className="error-message">{error}</p>}
                <button type="submit" className="login-button" disabled={loading}>
                    {loading ? <div className="loader"></div> : 'Se connecter'}
                </button>
                <button type="button" className="test-button" onClick={handleFillTestCredentialsJean}>
                    Remplir pour Jean
                </button>
                <button type="button" className="test-button" onClick={handleFillTestCredentialsEmma}>
                    Remplir pour Emma
                </button>
                <button type="button" className="test-button" onClick={handleFillTestCredentialsPaul}>
                    Remplir pour Paul
                </button>
                <button type="button" className="test-button" onClick={handleFillTestCredentialsClement}>
                    Remplir pour Clement
                </button>
            </form>
        </div>
    );
};

export default Login;
